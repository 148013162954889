import React, { Suspense, lazy } from 'react';
import './styles/globals.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import LoadingScreen from './components/LoadingScreen';


// Lazy load the components
const Navbar = lazy(() => import('./components/Navbar'));
const Homepage = lazy(() => import('./components/Homepage'));
const WorkSection = lazy(() => import('./components/WorkSection'));
const ServiceSection = lazy(() => import('./components/ServiceSection'));
const RegisterSection = lazy(() => import('./components/RegisterSection'));
const Footer = lazy(() => import('./components/Footer'));
const ServiceDetail = lazy(() => import('./components/ServiceDetail'));
const OtherServices = lazy(() => import('./components/OtherServices'));
const ImageGallery = lazy(() => import('./components/ImageGallery'));
const AboutUs = lazy(() => import('./components/AboutUs'));
// const ReviewSection = lazy(() => import('./components/ReviewSection'))

const RainbowTrail = lazy(() => import('./components/RainbowTrail'));




const App: React.FC = () => {
  const location = useLocation();

  const hideNavbarRoutes = ["/service/", "/other-service", "/image-gallary", "/newpage"];
  const showNavbar = !hideNavbarRoutes.some((route) => location.pathname.includes(route));

  return (
    <div className="App">
      <Suspense fallback={
        <div className="flex items-center justify-center h-screen">
          <ClipLoader color="#4F46E5" size={50} />
        </div>
      }>
      {/* <Suspense fallback={<LoadingScreen />}> */}
        {showNavbar && <Navbar />}
        <main>
          <Routes>
            <Route path='/' element={
              <>
                <section id='home'>
                  <Homepage />
                </section>
                <section id='about'>
                  <AboutUs />
                </section>
                <section id='explore'>
                  <WorkSection />
                </section>
                <section id='services'>
                  <ServiceSection />
                </section>
                {/* <section id='review'>
                  <ReviewSection />
                </section> */}
                <section id='register'>
                  <RegisterSection />
                </section>
                <section id='footer'>
                  <Footer />
                </section>
              </>
            } />
            <Route path="service/:title/:_id" element={<ServiceDetail />} />
            <Route path="other-service" element={<OtherServices />} />
            <Route path="image-gallary" element={<ImageGallery />} />
          </Routes>
        </main>
      </Suspense>
    </div>
  );
}

const WrappedApp: React.FC = () => {
  return (
    <Router>
      <RainbowTrail />
      <App />
    </Router>
  );
};

export default WrappedApp;