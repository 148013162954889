import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

interface Album {
  video: string[];
  clientName: string;
  images: string[];
}

interface AlbumsState {
  albumsData: Album[];
  loading: boolean;
  error: string | null;
}

const initialState: AlbumsState = {
  albumsData: [],
  loading: false,
  error: null,
};

export const fetchAlbums = createAsyncThunk(
  "albums/fetchAlbums",
  async (albumId: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(`https://arkapi.fun/api/v1/album/${albumId}`);
      if (response.data.status) {
        return response.data.data.map((album: { clientName: string; images: { url: string }[]; video: { url: string }[] }) => ({
          clientName: album.clientName,
          images: album.images.map((img) => img.url),
          video: album.video ? album.video.map((vid) => vid.url) : [],
        }));
      } else {
        throw new Error("Invalid response from server");
      }
    } catch (error: any) {
      return rejectWithValue(error.message || "Failed to fetch album data");
    }
  }
);

const albumsSlice = createSlice({
  name: "albums",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAlbums.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAlbums.fulfilled, (state, action) => {
        state.loading = false;
        state.albumsData = action.payload;
      })
      .addCase(fetchAlbums.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default albumsSlice.reducer;
