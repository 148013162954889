import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Define the Service type
interface Service {
  _id: string;
  title: string;
  images: string[];
}

// Define the state type
interface ServicesState {
  services: Service[];
  loading: boolean;
  error: string | null;
}

// Initial state
const initialState: ServicesState = {
  services: [],
  loading: false,
  error: null,
};

// Async thunk for fetching services
export const fetchServices = createAsyncThunk(
  "services/fetchServices",
  async () => {
    const response = await axios.get("https://arkapi.fun/api/v1/get-services");
    return response.data.data.map((service: any) => ({
      _id: service._id,
      title: service.name,
      images: service.images.map((img: any) => img.url),
    }));
  }
);

// Services slice
const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchServices.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchServices.fulfilled, (state, action) => {
        state.loading = false;
        state.services = action.payload;
      })
      .addCase(fetchServices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch services";
      });
  },
});

export default servicesSlice.reducer;
