import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

interface Service {
  _id: string;
  name: string;
  images: { url: string; _id: string }[];
}

interface RegisterState {
  services: Service[];
  isLoading: boolean;
  error: string | null;
  registrationStatus: string | null;
}

const initialState: RegisterState = {
  services: [],
  isLoading: false,
  error: null,
  registrationStatus: null,
};

// Thunks for API calls
export const fetchServices = createAsyncThunk(
  'register/fetchServices',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch('https://arkapi.fun/api/v1/get-services');
      const data = await response.json();
      if (!data.status) throw new Error('Failed to fetch services');
      return data.data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const sendRegistration = createAsyncThunk(
  'register/sendRegistration',
  async (
    formData: { fullName: string; email: string; message: string; service: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await fetch('https://arkapi.fun/api/v1/send-mail', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ...formData,
          subject: 'Feedback on Product',
        }),
      });
      const data = await response.json();
      if (!data.status) throw new Error('Failed to send message');
      return 'Message sent successfully!';
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    clearRegistrationStatus(state) {
      state.registrationStatus = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchServices
      .addCase(fetchServices.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchServices.fulfilled, (state, action: PayloadAction<Service[]>) => {
        state.isLoading = false;
        state.services = action.payload;
      })
      .addCase(fetchServices.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // Handle sendRegistration
      .addCase(sendRegistration.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(sendRegistration.fulfilled, (state, action: PayloadAction<string>) => {
        state.isLoading = false;
        state.registrationStatus = action.payload;
      })
      .addCase(sendRegistration.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { clearRegistrationStatus } = registerSlice.actions;

export default registerSlice.reducer;
