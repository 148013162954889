import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Define the interface for image data
interface ImageState {
  images: string[];
  loading: boolean;
  error: string | null;
}

const initialState: ImageState = {
  images: [],
  loading: false,
  error: null,
};

// Async thunk for fetching images
export const fetchImages = createAsyncThunk("images/fetchImages", async () => {
  const response = await axios.get("https://arkapi.fun/api/v1/get-images");
  const { status, data } = response.data;

  if (!status) {
    throw new Error("Failed to load images.");
  }

  return data.flatMap((item: { images: string[] }) => item.images);
});

const imageSlice = createSlice({
  name: "images",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchImages.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchImages.fulfilled, (state, action) => {
        state.loading = false;
        state.images = action.payload;
      })
      .addCase(fetchImages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Error fetching images.";
      });
  },
});

export default imageSlice.reducer;
