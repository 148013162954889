import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Define the Service type
interface File {
  url: string;
  _id: string;
}

interface Service {
  _id: string;
  name: string;
  description: string;
  files: File[];
}

// Define the state type
interface OtherServicesState {
  services: Service[];
  loading: boolean;
  error: string | null;
}

// Initial state
const initialState: OtherServicesState = {
  services: [],
  loading: false,
  error: null,
};

// Async thunk for fetching other services
export const fetchOtherServices = createAsyncThunk(
  "otherServices/fetchOtherServices",
  async () => {
    const response = await axios.get("https://arkapi.fun/api/v1/get-other-services");
    return response.data.data.map((service: any) => ({
      _id: service._id,
      name: service.name,
      description: service.description,
      files: service.files.map((file: any) => ({
        _id: file._id,
        url: file.url,
      })),
    }));
  }
);

// Other Services slice
const otherServicesSlice = createSlice({
  name: "otherServices",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOtherServices.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOtherServices.fulfilled, (state, action) => {
        state.loading = false;
        state.services = action.payload;
      })
      .addCase(fetchOtherServices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch other services";
      });
  },
});

export default otherServicesSlice.reducer;
