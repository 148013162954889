import { configureStore } from "@reduxjs/toolkit";
import imageReducer from "./slice/imageSlice";
import servicesReducer from "./slice/servicesSlice";
import registerReducer from './slice//registerSlice';
import albumsReducer from "./slice/albumsSlice";
import otherServicesReducer from "./slice/otherServicesSlice";


const store = configureStore({
  reducer: {
    images: imageReducer,
    services: servicesReducer,
    register: registerReducer,
    albums: albumsReducer,
    otherServices: otherServicesReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
